@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@500&family=Orbitron:wght@500&family=Quicksand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@500&family=Orbitron:wght@400;500&family=Quicksand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Josefin+Sans:wght@500&family=Orbitron:wght@400;500&family=Quicksand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@500&display=swap');

.home {
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Quicksand', sans-serif;
    background: #dfe4ce;
}

.content {
    width: 70%;
    box-shadow: 0.1rem 0.1rem 1.5rem rgba(0, 0, 0, 0.2);
    padding: 24px 12px;
    margin-top: 5%;
    background-color: #f5f5f5;
    border-radius: 10px;
}

.intro {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10%;

}

.bold {
    font-weight: bold;
}

.name {
    font-size: 60px;
    font-family: 'Teko', sans-serif;
    font-style: normal;

}

.paragraph {
    font-size: 16px;
    font-style: normal;
    font-family: 'Quicksand', sans-serif;
}

.abstractContent {
    font-style: normal;
    font-family: 'Quicksand', sans-serif;
    font-size: 15px;
}

.buttons {
    display: flex;
    justify-content: space-between;
    width: 100px;
    column-gap: 5px;
    row-gap: 5px;
}

.button {
    width: 100px;
    border-radius: 40px;
    border: 1px solid #B39BC8;
    background: white;
    cursor: pointer;

}

.anchor {
    text-decoration: none;
    color: black;
}

.image {
    border-radius: 80px;
    width: 300px;
    height: 250px;
}

.abstract {
    margin-top: 20px;
    border-top: 1px solid grey;
}

@media (max-width: 700px) {
    .home {
        align-items: flex-start;
        overflow-x: none;
    }

    .abstract p {
        font-size: 20px;
    }

    .content {
        height: 100%;
        flex-direction: column;
        width: 100%;
        text-align: center;
        margin-top: 2%;
        padding: 12px;
        box-shadow: none;
    }

    .name {
        font-size: 32px;
    }

    .paragraph {
        font-size: 20px;
    }

    .image {
        width: 200px;
        height: 150px;
    }
}