@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Roboto+Slab:wght@300;400&display=swap');

.SideDrawer {
    position: fixed;
    width: 300px;
    max-width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: #F5F5F5;
    padding: 32px 16px;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
    font-family: 'Josefin Sans', sans-serif;
   
}

@media (min-width: 500px) {
    .SideDrawer {
        display: none;
    }
}

.Open {
    transform: translateX(0);
}

.Close {
    transform: translateX(-100%);
}