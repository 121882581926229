.NavigationItem {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
}

.NavigationItem a {
    color: black;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
    color: #40A4C8;
    border-bottom: 2px solid #40A4C8;
}

@media (max-width:500px) {
    .NavigationItem a {
        width:22%
    }
}

@media (min-width: 500px) {
    .NavigationItem {
        margin: 0;
        display: flex;
        height: 70%;
        width: auto;
        align-items: center;
    }
    
    .NavigationItem a {
     
        font-weight:600;
        height: 100%;
        padding: 16px 19px;
        border-bottom: 4px solid transparent;
    }
    
    .NavigationItem a:hover,
    .NavigationItem a:active,
    .NavigationItem a.active {
        /* background-color: #8F5C2C; */
        border-bottom: 2px solid #40A4C8;
        color: #007FFF;
 
    }
}