@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@500&family=Quicksand&display=swap');

.parent {
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Quicksand', sans-serif;
    background: #dfe4ce;
}

.subh2 {
    font-family: 'Orbitron', sans-serif;

}

.cardHeader {
    text-align: center;
}

.abstractResearch {
    font-family: 'Quicksand', sans-serif;
    font-size: 12pt;
    font-style: normal;
}

.list li {
    list-style-type: none;
    width: 600px;
    /* height: 100px; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 10px;
    padding: 16px;
    box-shadow: 0.1rem 0.1rem 1.5rem rgba(0, 0, 0, 0.2);
    background-color: #f5f5f5;
}

.list li a {
    text-decoration: none;
    color: #007FFF;
}

.list li a:hover {
    text-decoration: underline;
    text-decoration-color: #40A4C8;
    text-underline-offset: 6px;
}

@media (max-width: 700px) {
    .parent {
        padding: 0 0
    }

    .list li {
        width: 90%;
    }

    .list {
        padding-left: 0 !important;
    }

}