@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital@1&family=Quicksand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@500&family=Quicksand&display=swap');

.parent {
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Quicksand', sans-serif;
    background: #dfe4ce;
}

.list {
    padding-bottom: 10%;
}


.list li {
    list-style-type: none;
    width: 600px;
    margin: 10px;
    padding: 16px;
    background-color: #f5f5f5;
    box-shadow: 0.1rem 0.1rem 1.5rem rgba(0, 0, 0, 0.2);
}

.listItemFirst {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.list li a {
    text-decoration: none;
    color: #007FFF;
}

.list li a:hover {
    text-decoration: underline;
    text-decoration-color: #40A4C8;
    text-underline-offset: 6px;
}

p {
    font-style: italic;
    font-family: 'Cormorant Garamond', serif;
    font-size: 14pt;
}

/*.cardHeader {
    font-family: 'Orbitron', sans-serif;
    font-weight: 400px;
}*/

h1 {
    font-family: 'Orbitron', sans-serif;
    font-weight: 500px;
}

@media (max-width: 700px) {
    .parent {
        padding: 0 0
    }

    .list li {
        width: 88%;
    }

    .list {
        padding-left: 0 !important;
    }

}