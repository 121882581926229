@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@500&family=Orbitron:wght@400;500&family=Quicksand&display=swap');

.SideDrawer {
    position: fixed;
    width: 600px;
    max-width: 35%;
    height: 100%;
    /* left: 0; */
    right: 0;
    top: 0;
    z-index: 200;
    /*background-color: #f2eee1; */
    background-color: #e7e5bb;
    padding: 32px 16px;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
    font-family: 'Quicksand', sans-serif;
}

.Title {
    font-size: 41px;
    font-weight: bold;
    font-family: 'Orbitron', sans-serif;

}

.Title span {
    font-style: italic;
}

.Description {
    font-family: "Quicksand", sans-serif;
}

.CloseWindow {
    float: right;
    position: relative;
    font-size: 25px;
    color: black;
    cursor: pointer;
}

.CloseWindow:hover {
    -webkit-animation: spin 0.4s linear normal;
    -moz-animation: spin 0.4s linear normal;
    animation: spin 0.4s linear normal;
}

.Contact {
    color: black;
    margin-top: 4vh;
    margin-left: 3vw;
    width: 70%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
    /* text-align: center; */


}

.Contact h4 {
    font-weight: bold;
    font-size: 16px;
}

.Captions {
    font-weight: bold;
    text-decoration: underline;
    text-decoration-color: #B39BC8;
    text-underline-position: under;
    font-size: 20px;
    font-family: "Quicksand", sans-serif;
}

.contactInfo {
    font-family: "Quicksand", sans-serif;
}

/* .Icons {
    display: flex;
    width:15vw;
    justify-content: space-evenly;
    font-size: 2vw;
    cursor: pointer;
} */

.Open {
    transform: translateX(0);

}

.Close {
    transform: translateX(100%);


}

.Logo {
    height: 11%;
    margin-bottom: 32px;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}



@media (max-width:1000px) {
    .SideDrawer {
        max-width: 40%;
    }
}


@media (max-width:600px) {

    .SideDrawer {
        max-width: 65%;
    }

    .Contact {
        font-size: 14px;
        align-items: flex-start;
        text-align: start;
    }

    .Contact h4 {
        font-size: 18px;

    }

    .Title {
        font-size: 24px;
    }

    .Description {
        font-size: 12px;
        margin: 0px 12px;
    }

    .Icons {
        font-size: 20px;
        width: 100%;
    }
}