@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@500&family=Orbitron:wght@400;500&family=Quicksand&display=swap');

.NavigationItems {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100%;
    font-family: 'Orbitron', sans-serif;
    font-size: 24px;
}

.NavigationItem {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
}


.NavigationItem:hover {
    color: #40A4C8;
}

@media (min-width: 500px) {
    .NavigationItems {
        flex-flow: row;
        font-size: 16px;
    }
}

@media (min-width: 500px) {
    .NavigationItem {
        margin: 0;
        display: flex;
        height: 30%;
        width: auto;
        align-items: center;
        font-weight: bold;
    }

    .NavigationItem a {
        color: black;
        font-weight: 600;
        height: 100%;
        padding: 0px 19px;
        border-bottom: 4px solid transparent;
    }

    .NavigationItem:hover {
        /* background-color: #8F5C2C; */

        color: #007FFF;
        cursor: pointer;

    }
}